import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { useLangContext } from '../../context/lang.context'
import Headline from '../../components/Headline'
import Section from '../../components/Section'
import BannerImage from '../../components/BannerImage'

const content = {
  managment: {
    title: {
      pl: 'Zarząd',
      en: 'Management',
    },
    data: [
      {
        name: 'Szymon Rusztyn',
        title: 'Prezes Zarządu',
        desc: {
          pl: `Szymon Rusztyn od ponad 18 lat związany jest z branżą TSL, kreując nowoczesne rozwiązania w zarządzaniu operacjami. Swoją karierę rozpoczął od stanowiska operacyjnego jako spedytor. Szybko zdobywał doświadczenie, przechodząc kolejne szczeble rozwoju zawodowego.  Dzięki konsekwentnej pracy i umiejętnościom strategicznym zbudował jedną z wiodących firm spedycyjnych na polskim rynku - Virtus Logistics, zatrudniającą 180 pracowników w kilkunastu oddziałach. Jego priorytetem jest wspólne działanie wszystkich spółek partnerskich oraz dynamiczny rozwój na nowych rynkach.`,
          en: 'He has been a part of the TSL industry since 2002. A graduate of MBA studies at the WSB University in Gdańsk. Co-founder and President of Omida VLS Sp. z o.o. He is motivated by the ever continuing success in business. He believes that an important element of every person`s development is self-improvement, which is why is a mentor in the Columbus Foundation and a development program for high school students known as The Grade. As the leader of OMIDA Group, he supports activities aimed at the development of employee competence and integration. He is involved in many local initiatives. He lives by the motto that "by learning and applying your knowledge, you can solve every problem, overcome every obstacle and achieve every goal you set"',
        },
        image: 'image4',
      },
      {
        name: 'Michał Pikura',
        title: 'Członek Zarządu',
        desc: {
          pl: `Michał Pikura to praktyk i specjalista z 15-letnim doświadczeniem w branży TSL, który rozpoczynał karierę jako spedytor międzynarodowy. Po dołączeniu do Virtus Logistics, dzięki swojemu zaangażowaniu i rozwojowi zawodowemu, awansował na stanowisko Dyrektora Regionalnego. Zarządzał zespołami i wspierał rozwój struktur organizacyjnych, a jego priorytetem było tworzenie efektywnych procesów oraz budowanie relacji z kluczowymi klientami. W Omida VLS odpowiada za koordynację procesów integracyjnych oraz rozwój strategiczny. Jego analityczne myślenie i zaangażowanie w usprawnianie działań pozwolą marce osiągnąć najwyższy poziom efektywności operacyjnej.`,
          en: `With the TSL industry for over 20 years. Since 2010, he has been successfully managing the OMIDA Group of companies, thus creating, under the name of the company TSL in Poland, revenue in 2021, awarding PLN 1,100,000,000. A graduate of the Faculty of Economics at the University of Gdańsk. He graduated with a master's degree with a specialization in Sea Trade. A graduate of the MBA studies at the WSB University in Gdańsk. Member of the Council of Experts at the University of Gdańsk. Founder of the Columbus Foundation focused on educating business leaders and improving youth skills. Mentor and one of the founders of Columbus High School.`,
        },
        image: 'image5',
      },
      {
        name: 'Maciej Daniluk',
        title: 'Członek Zarządu',
        desc: {
          pl: `Maciej Daniluk, z blisko 10-letnim doświadczeniem w controllingu finansowym, od 2015 roku związany jest z OMIDA Group. Pracował jako Business Controller i odpowiadał za raportowanie, kontrolę finansów oraz rozwój procesów operacyjnych. Jego zaangażowanie i wiedza pozwoliły na wdrożenie licznych usprawnień finansowych w Grupie. W Omida VLS czuwa nad strategicznym zarządzaniem finansami oraz rozwijaniem procesów wspierających działalność operacyjną.`,
          en: `With the TSL industry for over 20 years. Since 2010, he has been successfully managing the OMIDA Group of companies, thus creating, under the name of the company TSL in Poland, revenue in 2021, awarding PLN 1,100,000,000. A graduate of the Faculty of Economics at the University of Gdańsk. He graduated with a master's degree with a specialization in Sea Trade. A graduate of the MBA studies at the WSB University in Gdańsk. Member of the Council of Experts at the University of Gdańsk. Founder of the Columbus Foundation focused on educating business leaders and improving youth skills. Mentor and one of the founders of Columbus High School.`,
        },
        image: 'image6',
      },
    ],
  },
  supervisory: {
    title: {
      pl: 'Rada Nadzorcza',
      en: 'Management',
    },
    data: [
      {
        name: 'Marek Rostkowski',
        title: 'Członek Rady Nadzorczej',
        desc: {
          pl: `Marek Rostkowski od 2002 roku jest związany z branżą TSL. Absolwent studiów MBA Wyższej Szkoły Bankowej w Gdańsku. Współzałożyciel OMIDA Group. Jako prezes zarządu, w ciągu zaledwie 8 lat wprowadził firmę do czołówki 10 największych firm branży w kraju. Dziś OMIDA Group to grupa kilkunastu spółek, zatrudniających blisko 1000 osób w całym kraju. Jako mentor w Fundacji Columbus oraz lider programu rozwojowego dla młodzieży licealnej The Grade, dzieli się swoim doświadczeniem, inspirując kolejne pokolenia liderów. Jest współzałożycielem klubu OMIDA Yacht Club, który promuje żeglarstwo wśród lokalnej społeczności.`,
          en: 'He has been a part of the TSL industry since 2002. A graduate of MBA studies at the WSB University in Gdańsk. Co-founder and President of Omida VLS Sp. z o.o. He is motivated by the ever continuing success in business. He believes that an important element of every person`s development is self-improvement, which is why is a mentor in the Columbus Foundation and a development program for high school students known as The Grade. As the leader of OMIDA Group, he supports activities aimed at the development of employee competence and integration. He is involved in many local initiatives. He lives by the motto that "by learning and applying your knowledge, you can solve every problem, overcome every obstacle and achieve every goal you set"',
        },
        image: 'image1',
      },
      {
        name: 'Bartłomiej Glinka',
        title: 'Członek Rady Nadzorczej',
        desc: {
          pl: `Bartłomiej Glinka z branżą TSL związany jest od ponad 20 lat. Od 2010 z sukcesami zarządza grupą spółek Omida, tworząc tym samym jedną z najszybciej rozwijających się firm TSL w Polsce. Absolwent Wydziału Ekonomicznego Uniwersytetu Gdańskiego, z tytułem magistra na specjalności Handel Morski oraz absolwent studiów MBA Wyższej Szkoły Bankowej w Gdańsku. Pełni również rolę członka Rady Ekspertów Uniwersytetu Gdańskiego. Jest współzałożycielem i prezesem Fundacji Columbus, której głównym celem jest kształcenie młodzieży oraz liderów biznesu. Dzieli się tam swoją wiedzą i doświadczeniem z przyszłymi przedsiębiorcami. Jest mentorem i jednym z założycieli Liceum Columbus.`,
          en: `With the TSL industry for over 20 years. Since 2010, he has been successfully managing the OMIDA Group of companies, thus creating, under the name of the company TSL in Poland, revenue in 2021, awarding PLN 1,100,000,000. A graduate of the Faculty of Economics at the University of Gdańsk. He graduated with a master's degree with a specialization in Sea Trade. A graduate of the MBA studies at the WSB University in Gdańsk. Member of the Council of Experts at the University of Gdańsk. Founder of the Columbus Foundation focused on educating business leaders and improving youth skills. Mentor and one of the founders of Columbus High School.`,
        },
        image: 'image2',
      },
      {
        name: 'Daniel Franke',
        title: 'Członek Rady Nadzorczej',
        desc: {
          pl: `Daniel Franke od ponad 20 lat związany jest z branżą logistyczną, zdobywając doświadczenie zarówno w Polsce, jak i za granicą. Zrealizował wiele międzynarodowych projektów rozwijając dywizję logistyczną jednego ze światowych liderów branży w krajach zachodniej i południowej Europy. W 2022 roku dołączył do Grupy OMIDA jako Dyrektor Zarządzający i Członek Zarządu. Odpowiada za realizację strategii, koncentrując się na rozwoju logistyki kontraktowej, ekspansji międzynarodowej oraz cyfryzacji procesów operacyjnych. Jego doświadczenie i wiedza wspierają Grupę OMIDA w osiąganiu strategicznych celów biznesowych, w tym w działaniach związanych z fuzjami i przejęciami (M&A).`,
          en: `With the TSL industry for over 20 years. Since 2010, he has been successfully managing the OMIDA Group of companies, thus creating, under the name of the company TSL in Poland, revenue in 2021, awarding PLN 1,100,000,000.</br></br>A graduate of the Faculty of Economics at the University of Gdańsk. He graduated with a master's degree with a specialization in Sea Trade. A graduate of the MBA studies at the WSB University in Gdańsk. Member of the Council of Experts at the University of Gdańsk. Founder of the Columbus Foundation focused on educating business leaders and improving youth skills. Mentor and one of the founders of Columbus High School.`,
        },
        image: 'image3',
      },
    ],
  }
}

const Chiefs = () => {
  const { lang } = useLangContext()

  const query = useStaticQuery(graphql`
    {
      image1: file(
        absolutePath: { regex: "/images/poznaj-nas/marek_rostkowski.jpg/" }
      ) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
      image2: file(
        absolutePath: { regex: "/images/poznaj-nas/bartlomiej_glinka.jpg/" }
      ) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
      image3: file(
        absolutePath: { regex: "/images/poznaj-nas/daniel_franke.jpg/" }
      ) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
      image4: file(
        absolutePath: { regex: "/images/poznaj-nas/szymon_rusztyn.jpg/" }
      ) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
      image5: file(
        absolutePath: { regex: "/images/poznaj-nas/michal_pikura.jpg/" }
      ) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
      image6: file(
        absolutePath: { regex: "/images/poznaj-nas/maciej_daniluk.jpg/" }
      ) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
    }
  `)

  return (
    <>
      <Section top>
        <Headline
          h={2}
          title={content.managment.title[lang]}
          //
          large
          gradient
        />
      </Section>
      <Section mobile bottom>
        {content.managment.data.map(({ name, title, desc, image }, id) => (
          <BannerImage
            square
            title={name}
            subtitle={title}
            desc={desc[lang]}
            image={query[image]}
            key={id}
            h={3}
          />
        ))}
      </Section>
      <Section top>
        <Headline
          h={2}
          title={content.supervisory.title[lang]}
          //
          large
          gradient
        />
      </Section>
      <Section mobile bottom>
        {content.supervisory.data.map(({ name, title, desc, image }, id) => (
          <BannerImage
            square
            title={name}
            subtitle={title}
            desc={desc[lang]}
            image={query[image]}
            key={id}
            h={3}
          />
        ))}
      </Section>
    </>
  )
}

export default Chiefs
